import { click } from '@javascripts/lib/user-event/event-convertors/click'
import { view } from '@javascripts/lib/user-event/event-convertors/view'
import { action } from '@javascripts/lib/user-event/event-convertors/action'
import { swipe } from '@javascripts/lib/user-event/event-convertors/swipe'

export const E_NAME = {
  action,
  click,
  view,
  swipe,
}
