import mixpanel from 'mixpanel-browser'
import { ENV_MODE } from '@javascripts/constants/env'
import { getCookie, setCookie } from '@javascripts/utils/cookie'
import { getParam } from '@javascripts/utils/url-parse'

const MIXPANEL_SESSION_COOKIE_KEY = 'mixpanelSessionCookieKey'

const getOrCreateSessionId = () => {
  const sessionId = getCookie(MIXPANEL_SESSION_COOKIE_KEY)
  
  if (sessionId) {
    return sessionId
  }
  
  return new Date().getTime() + ''
}

const renewSessionId = (sessionId) => {
  setCookie(MIXPANEL_SESSION_COOKIE_KEY, sessionId, (1 / 24) * 0.5) // 쿠키 지속시간 30분
  
  const utmDataFromCookie = getCookie('utm_data')
  if (utmDataFromCookie) {
    setCookie('utm_data', encodeURI(utmDataFromCookie), (1 / 24) * 0.5) // 쿠키 지속시간 30분
  }
}

const initMixpanel = () => {
  mixpanel.init('1511c6cc7b5d70c0a8b23c7f7c8508c5', {
    debug: NODE_ENV === ENV_MODE.development,
  })
  
  renewSessionId(getOrCreateSessionId())
  
  const userEl = document.querySelector('lawandgood-user')
  
  mixpanel.identify(userEl.innerText || null)
  
  const userInfo = {
    date_joined: userEl.getAttribute('date-joined'),
    is_staff: userEl.getAttribute('is-staff'),
    social_account: userEl.getAttribute('social-account'),
    is_agree_push: userEl.getAttribute('is-agree-push'),
    is_agree_sms: userEl.getAttribute('is-agree-sms'),
    is_agree_email: userEl.getAttribute('is-agree-email'),
  }
  
  if (userEl.getAttribute('status') === '일반 회원') {
    userInfo.questionnaire_count = userEl.getAttribute('questionnaire-count')
  } else {
    userInfo.is_verified = userEl.getAttribute('verified')
    userInfo.is_profile = userEl.getAttribute('is-profile')
    userInfo.profile_created_at = userEl.getAttribute('profile_created_at')
    userInfo.main_category = userEl.getAttribute('main_category')
    userInfo.lawyer_board_count = userEl.getAttribute('lawyer-board-count')
    userInfo.lawyer_major_board_count = userEl.getAttribute('lawyer-major-board-count')
    userInfo.lawyer_minor_board_count = userEl.getAttribute('lawyer-minor-board-count')
    userInfo.on_realtime_alarm_talk = userEl.getAttribute('on-realtime-alarm-talk')
    userInfo.is_partnership = userEl.getAttribute('is-partnership')
    userInfo.cash_balance = userEl.getAttribute('cash-balance')
    
    if (userEl.getAttribute('verified-at')) {
      userInfo.verified_at = userEl.getAttribute('verified-at')
    }
  }
  
  const utmSource = getParam('utm_source')
  const [utmSourceParam, initialUtmSourceParam] = utmSource ? [{ utm_source: utmSource }, { initial_utm_source: utmSource }] : [{}, {}]
  const utmMedium = getParam('utm_medium')
  const [utmMediumParam, initialUtmMediumParam] = utmMedium ? [{ utm_medium: utmMedium }, { initial_utm_medium: utmMedium }] : [{}, {}]
  const utmCampaign = getParam('utm_campaign')
  const [utmCampaignParam, initialUtmCampaignParam] = utmCampaign ? [{ utm_campaign: utmCampaign }, { initial_utm_campaign: utmCampaign }] : [{}, {}]
  const utmTerm = getParam('utm_term')
  const [utmTermParam, initialUtmTermParam] = utmTerm ? [{ utm_term: utmTerm }, { initial_utm_term: utmTerm }] : [{}, {}]
  const utmContent = getParam('utm_content')
  const [utmContentParam, initialUtmContentParam] = utmContent ? [{ utm_content: utmContent }, { initial_utm_content: utmContent }] : [{}, {}]
  const utmId = getParam('utm_id')
  const [utmIdParam, initialUtmIdParam] = utmId ? [{ utm_id: utmId }, { initial_utm_id: utmId }] : [{}, {}]
  
  const gclid = getParam('gclid')
  const [gclidParam, initialGclidParam] = gclid ? [{ gclid }, { initial_gclid: gclid }] : [{}, {}]
  
  mixpanel.people.set_once({
    'status': userEl.getAttribute('status'),
    ...initialGclidParam,
    ...initialUtmSourceParam,
    ...initialUtmMediumParam,
    ...initialUtmCampaignParam,
    ...initialUtmTermParam,
    ...initialUtmContentParam,
    ...initialUtmIdParam,
  })
  mixpanel.people.set({
    ...userInfo,
    ...gclidParam,
    ...utmSourceParam,
    ...utmMediumParam,
    ...utmCampaignParam,
    ...utmTermParam,
    ...utmContentParam,
    ...utmIdParam,
  })
}

export const logMixpanelEvent = (eventName, eventProperties, insertId) => {
  const sessionId = getOrCreateSessionId()
  renewSessionId(sessionId)
  
  mixpanel.track(eventName, {
    ...eventProperties,
    session_id: sessionId,
    $insert_id: insertId,
  })
}

initMixpanel()