import { logAmplitudeEvent } from '@javascripts/lib/user-event/amplitude'
import { logMixpanelEvent } from '@javascripts/lib/user-event/mixpanel'
import { uuidv4 } from '@javascripts/utils/uuidv4'

export const logEvent = (eventName, eventProperties) => {
  const insertId = uuidv4()
  const properties = {
    ...eventProperties,
    'current_url': location.href,
    'page_path': location.pathname,
    'url_parameters': location.search,
  }
  
  if (eventName.includes('view : ')) {
    const a = document.createElement('a')
    a.href = document.referrer
    const host = String(a.host)
    
    properties.referrer_url = a.href
    properties.referrer_source = host
  }
  
  logAmplitudeEvent(eventName, { ...properties, insert_id: insertId })
  logMixpanelEvent(eventName, properties, insertId)
}