export const utmTypes = ['utm_id', 'utm_content', 'utm_term', 'utm_source', 'utm_medium', 'utm_campaign']

const initialUtmMap = {
  utm_campaign: '',
  utm_content: '',
  utm_medium: '',
  utm_source: '',
  utm_term: '',
  utm_id: '',
}

export const getUtm = (utmTypes) => {
  return utmTypes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: window.getParam(curr),
    }),
    initialUtmMap,
  )
}

export default getUtm