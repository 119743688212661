import { deepConcatObj, isEmptyObj } from '@javascripts/utils/object'

let instance = null
let context = {}

export class Context {
    constructor(obj = {}) {
        if (instance) {
            if (isEmptyObj(obj) === false || Object.isFrozen(context)) {
                const newContext = deepConcatObj(instance.getContext(), obj)
                instance.setContext(newContext)
            }
            
            return instance
        }
        instance = this
        context = deepCopy(obj)
        
        window.addEventListener('DOMContentLoaded', () => {
            deepFreeze(context)
        })
    }
    
    getContext() {
        return context
    }
    
    setContext(obj) {
        if (Object.isFrozen(context)) return
        
        context = obj
    }
}