import { SWIPE_EVENTS } from '@javascripts/lib/user-event/event-names/swipe'

let instance = null
const swipeEvents = {}

class Swipe {
  constructor() {
    if (instance) {
      return instance
    }
    instance = this
    
    Object.keys(SWIPE_EVENTS).forEach((key) => {
      Object.defineProperty(
        swipeEvents,
        key,
        {
          value: `swipe : ${key}`,
        },
      )
    })
  }
  
  getInstance() {
    return swipeEvents
  }
}

export const swipe = new Swipe().getInstance()