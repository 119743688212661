export const ACTION_EVENTS = {
  counsel_closed: '',
  counsel_resumed: '',
  set_alarm_talk_complete: '',
  status_change: '',
  set_alarm_talk_off: '',
  lawyer_paid_counsel_complete: '',
  'request complete': '',
  view_first_offer_client: '',
  swipe_best_success_case: '',
  start_lawyer_introduction_video: '',
  'open number complete': '',
  try_search: '',
  input_board_import_search: '',
  change_board_import_ordering: '',
  change_board_import_category: '',
  create_ai_submit_btn: '',
}
