import * as base from '@javascripts/base'
import { setLazyLoader } from '@javascripts/utils/IO-lazy-loader'
import { setGlobalUtils } from '@javascripts/global'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'styles/base.scss'
import 'styles/navigation.scss'
import 'styles/footer.scss'
import 'styles/kakao_channel.scss'
import { setScreenSize } from '@javascripts/utils/dom'
import { authTokenManager } from '@javascripts/utils/authTokenManager'

async function onIt() {
  setGlobalUtils()
  
  setLazyLoader()
  base.initAxios()
  base.initUserStatus()
  base.initVue()
  base.initCustomEvent()
  base.initUTM()
  
  window.addEventListener('load', async () => {
    base.printLogo()
    
    setScreenSize()
    
    try {
      await authTokenManager.regenerateTokens()
    } catch (e) {
      // RefreshToken is expired or doesn't exist
    } finally {
      authTokenManager.allowRefreshing()
    }
  })
}

onIt()
