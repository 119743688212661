export function setLazyLoader() {
  document.addEventListener('DOMContentLoaded', function () {
    const lazyLoadImages = document.querySelectorAll('.lazy')
    
    if ('IntersectionObserver' in window) {
      const io = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target
            image.classList.remove('lazy')
            
            const dataSrc = image.getAttribute('data-webp-src') || image.getAttribute('data-src')
            
            image.setAttribute('style', 'background-image: url(\'' + dataSrc + '\')')
            observer.unobserve(image)
          }
        })
      })
      
      lazyLoadImages.forEach((image) => {
        io.observe(image)
      })
    } else {
      let lazyLoadThrottleTimeout
      
      function lazyLoad() {
        if (lazyLoadThrottleTimeout) {
          clearTimeout(lazyLoadThrottleTimeout)
        }
        
        lazyLoadThrottleTimeout = setTimeout(function () {
          const scrollTop = window.pageYOffset
          
          lazyLoadImages.forEach((img) => {
            if (img.offsetTop < (window.innerHeight + scrollTop)) {
              const src = img.getAttribute('data-src')
              img.setAttribute('style', 'background-image: url(\'' + src + '\')')
              img.classList.remove('lazy')
            }
          })
          
          if (lazyLoadImages.length === 0) {
            document.removeEventListener('scroll', lazyLoad)
            window.removeEventListener('resize', lazyLoad)
            window.removeEventListener('orientationChange', lazyLoad)
            window.removeEventListener('load', lazyLoad)
          }
        }, 20)
      }
      
      document.addEventListener('scroll', lazyLoad)
      window.addEventListener('resize', lazyLoad)
      window.addEventListener('orientationChange', lazyLoad)
      window.addEventListener('load', lazyLoad)
    }
  })
}
