import { CLICK_EVENTS } from '@javascripts/lib/user-event/event-names/click'

let instance = null
const clickEvents = {}

class Click {
  constructor() {
    if (instance) {
      return instance
    }
    instance = this
    
    Object.keys(CLICK_EVENTS).forEach((key) => {
      Object.defineProperty(
        clickEvents,
        key,
        {
          value: `click : ${key}`,
        },
      )
    })
  }
  
  getInstance() {
    return clickEvents
  }
}

export const click = new Click().getInstance()