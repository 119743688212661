import { ACTION_EVENTS } from '@javascripts/lib/user-event/event-names/action'

let instance = null
const actionEvents = {}

class Action {
  constructor() {
    if (instance) {
      return instance
    }
    instance = this
    
    Object.keys(ACTION_EVENTS).forEach((key) => {
      Object.defineProperty(
        actionEvents,
        key,
        {
          value: key,
        },
      )
    })
  }
  
  getInstance() {
    return actionEvents
  }
}

export const action = new Action().getInstance()