import { ENV_MODE } from '@javascripts/constants/env'

export function getDT(keyChain, defaultValue = undefined) {
    if (typeof keyChain !== 'string') {
        console.error(`KeyChain is not string (${JSON.stringify(keyChain)}). ${JSON.stringify(defaultValue)} is returned`)
        return defaultValue
    }
    
    let isFail = false
    const keyList = keyChain.split('.')
    const returnValue = keyList.reduce((nextObj, key) => {
        if (nextObj === undefined || key === '') return defaultValue
        
        if (key in nextObj) {
            return nextObj[key]
        }
        
        isFail = true
        return defaultValue
    }, new Context().getContext())
    
    if (isFail) {
        console.error(`Can't find CONTEXT.${keyChain}. ${JSON.stringify(defaultValue)} is returned.\n`)
    }
    return returnValue
}

export function checkDT(DT) {
    if (NODE_ENV === ENV_MODE.production || Array.isArray(DT) === false) return true
    
    let isPass = true
    DT.forEach((DTKey) => {
        const value = getDT(DTKey)
        
        if (window.DT === undefined) window.DT = {}
        window.DT[DTKey] = value
        
        if (value === undefined) {
            isPass = false
        }
    })
    
    return isPass
}