import moment from 'moment-mini'
import Swal from 'sweetalert2'
import Vue from 'vue'
import Vuex from 'vuex'
import {
  axiosDELETE,
  axiosFormPOST,
  axiosGET,
  axiosJsonPOST,
  axiosPATCH,
  axiosPOST,
  axiosPUT,
  axiosQuerystringGET,
} from '@javascripts/utils/axios'
import { Context } from '@javascripts/core/context'
import { deleteCookie, getCookie, setCookie } from '@javascripts/utils/cookie'
import { concatObj, deepCopy, deepFreeze } from '@javascripts/utils/object'
import { abTest, abTestSplash, removeTestElem } from '@javascripts/utils/ab-test'
import { getParam, parseStatic } from '@javascripts/utils/url-parse'
import { baseMixin } from '@javascripts/mixins/base-mixin'
import commonSwal from '@javascripts/utils/common-swal'
import { imageError } from '@javascripts/utils/dom'
import { logEvent } from '@javascripts/lib/user-event/event-logger'
import { E_NAME } from '@javascripts/lib/user-event/event-names'
import getUtm, { utmTypes } from '@javascripts/utils/get-utm'
import * as lodash from 'lodash'

export function setGlobalUtils() {
  // ab-test
  window.abTest = abTest
  window.abTestSplash = abTestSplash
  window.removeTestElem = removeTestElem
  
  window.lodash = lodash
  
  // axios
  window.axiosGET = axiosGET
  window.axiosQuerystringGET = axiosQuerystringGET
  window.axiosPOST = axiosPOST
  window.axiosJsonPOST = axiosJsonPOST
  window.axiosFormPOST = axiosFormPOST
  window.axiosPUT = axiosPUT
  window.axiosDELETE = axiosDELETE
  window.axiosPATCH = axiosPATCH
  
  // context
  window.Context = Context
  
  // param
  window.getParam = getParam
  window.getUtm = getUtm
  window.utmTypes = utmTypes
  
  // cookie
  window.setCookie = setCookie
  window.getCookie = getCookie
  window.deleteCookie = deleteCookie
  
  // event
  window.logEvent = logEvent
  window.E_NAME = E_NAME
  
  // object
  window.deepCopy = deepCopy
  window.deepFreeze = deepFreeze
  window.concatObj = concatObj
  
  // parseStatic
  window.parseStatic = parseStatic
  
  // dom utils
  window.imageError = imageError
  
  /* node_modules */
  // moment
  window.moment = moment
  
  // Swal
  window.swal = (text) => {
    Swal.fire({ text: text })
  }
  
  window.Swal = Swal.mixin({
    showClass: {
      popup: 'swal2-noanimation',
    },
    hideClass: {
      popup: '',
    },
    confirmButtonText: '확인',
    confirmButtonColor: '#435D5B',
    cancelButtonColor: '#999999',
    cancelButtonText: '취소',
  })
  window.commonSwal = commonSwal
  
  // Vue
  window.Vue = Vue
  window.Vuex = Vuex
  window.baseMixin = baseMixin
}
