export const CLICK_EVENTS = {
  '1on1_case_tab': '',
  all_case_tab: '',
  author_nickname: '',
  board_head_menu: '',
  bookmark_case_tab: '',
  breadcrumb: '',
  btn_alarm_talk: {
    current_status: ['first', 'on', 'off'],
  },
  btn_all_check: '',
  btn_all_check_cancel: '',
  btn_append_bookmark: '',
  btn_apply_filter: '',
  btn_arrow: '',
  btn_back_in_alarm_talk: '',
  btn_board_import: '',
  btn_board_import_bookmark: '',
  btn_continue_writing: '',
  btn_direct_counsel: '',
  btn_dropdown_arrow: '',
  btn_filter: '',
  btn_frequent_phrases: '',
  btn_go_to_cash_store: '',
  btn_main_category: '',
  btn_more_in_counsel: '',
  btn_my_request_look: '',
  btn_next_in_alarm_talk: '',
  btn_remove_bookmark: '',
  btn_status_change: '',
  btn_step_filter: '',
  btn_step_filter_detail: '',
  btn_subcategory: '',
  cancel_change_time: '',
  case_cta: '',
  category_filter: '',
  category_in_alarm_talk_modal: '',
  change_alarm_talk_status: '',
  change_time: '',
  check_request_info: '',
  client_report_link: '',
  close_online_counsel: '',
  complete_set_alarm_talk: '',
  counsel_card: '',
  etc_case_tab: '',
  import_board_btn: '',
  lawyer_card: '',
  lawyer_offer_btn: '',
  lawyer_profile: '',
  lawyer_profile_btn: '',
  more_btn: '',
  multi_select_checkbox_in_counsel_list: '',
  multi_select_counsel_card: '',
  nps_modal_220426: '',
  pagination: '',
  print_case: '',
  questionnaire_info_filter: '',
  quotation_active_tab: '',
  received_questionnaires_page_update_modal: '',
  received_request_card: '',
  region_filter: '',
  region_in_alarm_talk_modal: '',
  report_client: '',
  request_complete: '',
  reset_filter: '',
  resume_online_counsel: '',
  see_request_content: '',
  see_reqeust_in_counsel: '',
  single_select_checkbox_in_counsel_list: '',
  slider_change_time: '',
  smart_cta: '',
  sorting_detail: '',
  start_set_alarm_talk: '',
  start_write_offer: '',
  sticky_btn: '',
  submit_request: '',
  tag: '',
  toggle_on_off_alarm_talk: '',
  tooltip_safe_number: '',
  top_btn_in_received_request: '',
  type_in_alarm_talk_modal: '',
  main_cta: '',
  search_input_field: '',
  go_to_lawyer_detail_in_online_counsel: '',
  close_alarm_talk_tooltip: '',
  questionnaire_file: '',
  download_questionnaire_all_files: '',
  see_more_manager_comment: '',
  search_result_in_lawyer_list: '',
  search_result: '',
  category_in_lawyer_list: '',
  lawyer_item: '',
  back_to_write_offer: '',
  expired_request: '',
  lawandgood_in_hamburger: '',
  btn_reservation_in_nudge_message: '',
  paid_counsel_info: '',
  go_to_service_price: '',
  go_to_online_counsel: '',
  btn_call: '',
  go_to_paid_counsel_page: '',
  go_to_schedule: '',
  complete_counsel: '',
  faq: '',
  btn_more_faq: '',
  faq_category: '',
  review_card: '',
  btn_nav_hamburger: '',
  banner_cta: '',
  only_one_request: '',
  profile_nav_var: '',
  lawyer_interview_in_profile: '',
  see_more_column: '',
  paid_counsel_in_profile: '',
  service_price_in_profile: '',
  see_detail_office_map: '',
  close_message_cta: '',
  message_cta: '',
  filter_detail_lawyer_counsel_type: '',
  pagenation_in_lawyer_profile: '',
  filter_in_lawyer_profile: '',
  request_in_lawyer_profile: '',
  change_date_range_tab: '',
  btn_lawyer_sns: '',
  success_case_thumbnail: '',
  legal_info_thumbnail: '',
  lawyer_introduction_video: '',
  press_link: '',
  best_success_case_thumbnail: '',
  filter_sub_category: '',
  filter_main_category: '',
  pagenation_in_success_case_tab: '',
  see_all_time_data: '',
  go_to_received_request_page: '',
  search_input_field_in_counsel_list: '',
  btn_open_number: '',
  btn_back_to_list: '',
  btn_missed_case: '',
  accepted_case_tab: '',
  btn_append_bookmark_in_board: '',
  btn_remove_bookmark_in_board: '',
  go_to_question_content: '',
  lawandgood_logo: '',
  lawyer_list_in_navigation: '',
  go_to_question_in_navigation: '',
  question_content_in_navigation: '',
  litigation_funding_in_navigation: '',
  btn_search_in_navbar: '',
  see_more_finance_content: '',
  close_mobile_nav: '',
  btn_select_category_page_in_navigation: '',
  btn_profile_edit_in_navigation: '',
  btn_received_request: '',
  btn_counsel: '',
  btn_cash_history: '',
  btn_my_page_in_navigation: '',
  btn_my_request: '',
  btn_purchase_list_page: '',
  btn_value_prop: '',
  untact_contract: '',
  upload_contract_file: '',
  send_contract_file: '',
  btn_private_information: '',
  btn_slide_testimonial: '',
  al_chatbot_in_navigation: '',
  company_introduction_in_navigation: '',
  btn_litigation_funding_in_lawyer_navigation: '',
  retain_a_lawyer_later_btn: '',
  retain_a_lawyer_btn: '',
  dont_retain_a_lawyer_btn: '',
  brochure_download_btn: '',
  counsel_result_send_btn: '',
  btn_nav_litigation_funding_management: '',
  btn_counsel_result_dashboard: '',
  btn_telephone_inquire: '',
  btn_kakao_inquire: '',
}
