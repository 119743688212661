export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0
}

export function isIterable(obj) {
  return Symbol.iterator in Object(obj)
}

export function checkEqualList(list1, list2) {
  if (list1 === list2) return true
  if (!list1 || !list2) return false
  if (list1.length !== list2.length) return false
  
  const sortedList1 = [...list1].sort()
  const sortedList2 = [...list2].sort()
  
  for (let i = 0; i < sortedList1.length; i++) {
    if (sortedList1[i] !== sortedList2[i]) return false
  }
  return true
}

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export function deepFreeze(obj) {
  const props = Object.keys(obj)
  
  props.forEach((name) => {
    const prop = obj[name]
    if (typeof prop === 'object' && prop !== null) {
      deepFreeze(prop)
    }
  })
  return Object.freeze(obj)
}

export function concatObj(...objList) {
  return objList.reduce((resultObj, obj) => {
    return {
      ...resultObj,
      ...obj,
    }
  }, {})
}

export function deepConcatObj(...objList) {
  const keyList = objList.reduce((keyList, obj) => ([...keyList, ...Object.keys(obj)]), [])
  
  return keyList.reduce((resultObj, key) => {
    resultObj[key] = objList.reduce((keyObj, obj) => {
      if (obj.hasOwnProperty(key) === false) return keyObj
      
      if (keyObj === undefined && typeof obj[key] !== 'object') {
        return obj[key]
      }
      
      return { ...keyObj, ...obj[key] }
    }, undefined)
    
    return resultObj
  }, {})
}

export function getFormData(obj) {
  const form = new FormData()
  
  Object.entries(obj).forEach((item) => {
    if (typeof item[1] !== 'object') {
      form.append(item[0], item[1])
      return
    }
    
    const preprocessItem = (innerItem) => {
      if (innerItem instanceof File || innerItem instanceof Blob) {
        return innerItem
      }
      
      if (typeof innerItem === 'object') {
        return JSON.stringify(innerItem)
      }
      return innerItem
    }
    
    if (isIterable(item[1])) {
      item[1].forEach((innerItem) => {
        form.append(item[0], preprocessItem(innerItem))
      })
    } else {
      form.append(item[0], preprocessItem(item[1]))
    }
  })
  return form
}

export function downloadFile(file) {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)
  
  const blob = new Blob([file], { type: file.type })
  
  const objectURL = window.URL.createObjectURL(blob)
  
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    window.navigator.msSaveOrOpenBlob(blob, file.name)
  } else {
    const link = document.createElement('a')
    link.href = objectURL
    link.download = file.name
    
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}
