import { defaultPlugin } from '@javascripts/utils/vue-plugin'
import { getCookie } from '@javascripts/utils/cookie'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'
import * as Sentry from '@sentry/vue'
import { getParam } from '@javascripts/utils/url-parse'

export const trackError = function (error, message = '') {
  axiosPOST(baseUrls.trackError, {
    error: JSON.stringify(error),
    message,
  })
}

export function initAxios() {
  axios.defaults.headers['X-CSRFToken'] = getCookie('csrftoken')
  axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
}

export function initUserStatus() {
  if (!baseUser.isAuthenticated) {
    setCookie('status', 'none', 3)
  } else if (baseUser.isLawyer) {
    setCookie('status', 'lawyer', 3)
  } else if (baseUser.isClient) {
    setCookie('status', 'client', 3)
  }
}

export function initUTM() {
  const utm_campaign = getParam('utm_campaign')
  const utm_content = getParam('utm_content')
  const utm_medium = getParam('utm_medium')
  const utm_source = getParam('utm_source')
  const utm_term = getParam('utm_term')
  const utm_id = getParam('utm_id')
  
  if (utm_id || utm_term || utm_content || utm_medium || utm_source || utm_campaign) {
    const encodedData = encodeURI(JSON.stringify({
      utm_campaign, utm_content, utm_medium, utm_source, utm_term, utm_id,
      landing_url: window.location.href,
    }))
    setCookie('utm_data', encodedData, (1 / 24) * 0.5)
  }
}

export function initVue() {
  Vue.use(defaultPlugin)
  Vue.use(VueLazyload)
  
  Sentry.init({
    Vue,
    environment: NODE_ENV,
    dsn: 'https://fdcb86fe2b4b4c5bb817a0ac134c04e6@o1065605.ingest.sentry.io/6057564',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0001,
    sampleRate: 1,
  })
}

export function initCustomEvent() {
  if (typeof window.CustomEvent === 'function') return false //If not IE
  
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }
  
  CustomEvent.prototype = window.Event.prototype
  window.CustomEvent = CustomEvent
}

export function printLogo() {
  const consoleMainStyle = [
    'font-size: 48px',
    'font-family: noto sans',
    'font-weight: 700',
    'color: white',
    'background-color: #252B35',
    'padding-right: 12px',
  ].join(';')
  
  console.log('%c Law&Good', consoleMainStyle)
}
