import axios from 'axios'
import { getCookie } from '@javascripts/utils/cookie'
import { jwtDecode } from 'jwt-decode'

class AuthTokenManager {
  authServerBaseUrl = authServerBaseUrl
  isRefreshing
  refreshQueue = []
  callbackAfterRefreshingQueue = []
  
  _refreshMinute
  
  constructor() {
    this.isRefreshing = false
    this._refreshMinute = 0
  }
  
  async regenerateTokens() {
    this.preventRefreshing()
    return this._callRefreshApi()
  }
  
  async _callRefreshApi() {
    const refreshToken = this.getRefreshToken()
    
    if (!refreshToken) {
      throw new Error('NO_REFRESH_TOKEN')
    }
    
    if (!this.validateAuthToken(refreshToken)) {
      throw new Error('EXPIRED_REFRESH_TOKEN')
    }
    
    try {
      const { data } = await axios.post(this.authServerBaseUrl + '/auth/refresh', {
        refreshToken,
      }, {
        withCredentials: true,
      })
      
      this.setAuthTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (e) {
      this.removeAuthTokens()
      throw(e)
    } finally {
      this.callbackAfterRefreshingQueue.forEach(({ callback, resolver }) => {
        callback({
          accessToken: this.getAccessToken(),
          refreshToken: this.getRefreshToken(),
        })
        resolver()
      })
      this.callbackAfterRefreshingQueue = []
    }
  }
  
  getAccessToken() {
    return getCookie('accessToken')
  }
  
  getRefreshToken() {
    return getCookie('refreshToken')
  }
  
  removeAuthTokens() {
    deleteCookie('accessToken')
    deleteCookie('refreshToken')
  }
  
  setAuthTokens({ accessToken, refreshToken }) {
    this.setAuthToken('accessToken', accessToken)
    this.setAuthToken('refreshToken', refreshToken)
  }
  
  // type : accessToken, refreshToken
  setAuthToken(type, authToken) {
    const decodedAuthToken = jwtDecode(authToken)
    const authTokenExpiredDate = new Date(decodedAuthToken.exp * 1000).toUTCString()
    setCookie(type, `${authToken}; expires=${authTokenExpiredDate}; secure;`)
  }
  
  validateAuthToken(authToken) {
    try {
      const decodedAuthToken = jwtDecode(authToken)
      const authTokenExpiredDate = new Date(decodedAuthToken.exp * 1000)
      const today = new Date()
      
      return today.getTime() < authTokenExpiredDate.getTime()
    } catch (e) {
      return false
    }
  }
  
  clearRefreshQueue() {
    this.refreshQueue.splice(0, this.refreshQueue.length)
  }
  
  preventRefreshing() {
    this.isRefreshing = true
  }
  
  allowRefreshing() {
    this.isRefreshing = false
  }
  
  checkRefreshRequiredByRemindMinute() {
    const accessToken = this.getAccessToken()
    
    if (!accessToken) return false
    
    const exp = jwtDecode(accessToken).exp
    const expiredDate = exp ? new Date(exp * 1000) : null
    
    if (!expiredDate) return false
    
    const minuteDiff = (expiredDate.getTime() - new Date().getTime()) / (1000 * 60)
    
    return !this.isRefreshing && minuteDiff < this._refreshMinute
  }
  
  addRequest(config) {
    return new Promise((resolve, reject) => {
      this.refreshQueue.push({
        delayedRequestConfig: { ...config, isSent: true },
        resolveDelayedRequest: resolve,
        rejectDelayedRequest: reject,
      })
    })
  }
  
  callAllDelayedRequests(axiosInstance) {
    this.refreshQueue.forEach((delayedRequest) => {
      const { delayedRequestConfig, resolveDelayedRequest, rejectDelayedRequest } = delayedRequest
      
      axiosInstance(delayedRequestConfig).then((res) =>
        resolveDelayedRequest(res),
      ).catch((e) =>
        rejectDelayedRequest(e),
      )
    })
  }
  
  rejectAllDelayedRequests(error) {
    this.refreshQueue.forEach((delayedRequest) => {
      const { rejectDelayedRequest } = delayedRequest
      
      rejectDelayedRequest(error)
    })
  }
  
  async callAfterRefreshing(callback) {
    return new Promise((resolve, _reject) => {
      this.callbackAfterRefreshingQueue.push({
        callback,
        resolver: resolve,
      })
    })
  }
}

export const authTokenManager = new AuthTokenManager()