import { VIEW_EVENTS } from '@javascripts/lib/user-event/event-names/view'

let instance = null
const viewEvents = {}

class View {
  constructor() {
    if (instance) {
      return instance
    }
    instance = this
    
    Object.keys(VIEW_EVENTS).forEach((key) => {
      Object.defineProperty(
        viewEvents,
        key,
        {
          value: `view : ${key}`,
        },
      )
    })
  }
  
  getInstance() {
    return viewEvents
  }
}

export const view = new View().getInstance()