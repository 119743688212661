/*
* amplitude CDN은 GTM에서 가져옴
* GTM에서 실행되는 앰플리튜드 플로우
* 무조건 window.onload 이후에 실행되어야 함
* 1. CDN init
* 2. set_user
* 3. log_pageview_event
* */

export const logAmplitudeEvent = (eventName, eventProperties) => {
  window.amplitude?.getInstance().logEvent(eventName, eventProperties)
}
