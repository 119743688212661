export const VIEW_EVENTS = {
  case_cta_section: '',
  confirm_contract_complete_modal: '',
  confirm_request_page: '',
  counsel_page: '',
  lawyer_card: '',
  lawyer_online_counsel_list_page: '',
  lawyer_online_counsel_page: '',
  loading_screen: '',
  multi_close_counsel_modal: '',
  my_request_detail_page: '',
  not_enough_cash_modal: '',
  nps_modal_220426: '',
  partnership_notice_modal: '',
  question_content: '',
  question_content_detail_page: '',
  question_content_list_page: '',
  question_title: '',
  resume_counsel_modal: '',
  safe_nudge_modal: '',
  slider_change_time: '',
  set_alarm_talk_category_modal: '',
  set_alarm_talk_home_modal: '',
  set_alarm_talk_region_modal: '',
  set_alarm_talk_start_modal: '',
  set_alarm_talk_time_modal: '',
  set_alarm_talk_type_modal: '',
  single_close_counsel_modal: '',
  status_change_modal: '',
  update_notice_modal_220614: '',
  write_offer_page: '',
  request_content_page: '',
  request_content_modal: '',
  mini_profile_page: '',
  lawyer_success_case_page: '',
  lawyer_column_page: '',
  reservation_nudge_message: '', // Use in GTM
  received_request_page: '',
  category_in_lawyer_list: '',
  lawyer_list_page: '',
  manage_paid_counsel_page: '',
  deposit_without_passbook_page: '',
  lawyer_detail_page: '',
  write_request_page: '',
  search_result_in_counsel_list: '',
  open_number_modal: '',
  already_submit_review_modal: '',
  submit_review_complete_modal: '',
  ask_contract_status_one_lawyer: '',
  create_profile_modal: '',
  review_process: '',
  finance_introduction_page: '',
  litigation_funding_application_lawyer_page: '',
  value_prop_page: '',
  diagnostics_complete_section: '',
  experiment_page: '',
  untact_contract_modal: '',
  private_information_page: '',
  online_counsel_page: '',
  navigation_mega_menu: '',
}
