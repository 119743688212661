import { checkDT, getDT } from '@javascripts/utils/django-template'
import { ENV_MODE } from '@javascripts/constants/env'
import { useStatic } from '@javascripts/utils/static'

export const defaultPlugin = {
  install: function (Vue) {
    Vue.getComponents = getComponents
    
    Vue.prototype.parseStatic = parseStatic
    Vue.prototype.getDT = getDT
    Vue.prototype.useStatic = useStatic
    
    if (NODE_ENV === ENV_MODE.development) {
      Vue.mixin({
        mounted() {
          if (this.$options._componentTag || this.$options.DT === undefined) return
          
          const fullDT = getFullDT(this.$options)
          if (checkDT(fullDT)) return
          
          printComponentError(this.$options)
        },
      })
    }
  },
}

function getComponents(obj) {
  if (NODE_ENV === ENV_MODE.production) return obj
  
  Object.keys(obj).forEach((key) => {
    const component = obj[key]
    const fullDT = getFullDT(component)
    if (checkDT(fullDT)) return
    
    printComponentError(component)
  })
  return obj
}

function getFullDT(component) {
  const DT = Array.isArray(component.DT) ? [...component.DT] : []
  if (!component.mixins) return DT
  
  return component.mixins.reduce((fullDT, mixin) => {
    if (!mixin.DT) return fullDT
    return [...fullDT, ...mixin.DT]
  }, DT)
}

function printComponentError(component) {
  console.error(`Please check ${component.name} component DT`)
}