export const findTarget = function (node, targetSelector) {
  const targetList = Array.from(document.querySelectorAll(targetSelector))
  
  while (true) {
    for (let i = 0; i < targetList.length; i++) {
      if (targetList[i] === node) {
        return node
      }
    }
    node = node.parentNode
    if (node.tagName === 'BODY') {
      return null
    }
  }
}

export const getIsMobile = () => {
  return window.screen.width <= 780
}

export const getIsDesktop = () => {
  return window.screen.width > 780
}

export const getScrollY = (target = window) => {
  if (target !== window) {
    return target.scrollTop
  }
  return window.scrollY || window.pageYOffset
}

export const customScrollTo = (x, y, target = window) => {
  if (target !== window) {
    target.scrollLeft = x
    target.scrollTop = y
    return
  }
  
  target.scrollTo(x, y)
}

export function smoothVerticalScrolling(offsetY, time = 300, target = window) {
  const scrollCycle = 100
  const timePiece = time / scrollCycle
  const scrollAmount = (offsetY - getScrollY(target)) / scrollCycle
  let currentTime = 0
  let currentScrollY = getScrollY(target)
  let i = 0
  
  const getWeight = (loopCount) => {
    if (loopCount < 20) return 1.4
    if (loopCount < 40) return 1.2
    if (loopCount >= 60) return 0.8
    if (loopCount >= 80) return 0.6
    return 1
  }
  
  return new Promise((resolve, reject) => {
    while (currentTime <= time) {
      if (Math.abs(currentScrollY - offsetY) <= scrollAmount / 2) break
      
      setTimeout((y) => {
        customScrollTo(0, y, target)
      }, currentTime, currentScrollY)
      
      currentTime += timePiece
      currentScrollY += scrollAmount * getWeight(i++)
    }
    setTimeout(() => {
      customScrollTo(0, offsetY, target)
      resolve(true)
    }, time)
  })
}

export const addCdn = (url, options = {}) => {
  const $cdnScript = document.createElement('script')
  
  $cdnScript.setAttribute('src', url)
  for (let key in options) {
    $cdnScript.setAttribute(key, options[key])
  }
  
  document.head.appendChild($cdnScript)
}

export const routeByForm = (url, data) => {
  const $form = document.createElement('form')
  $form.setAttribute('method', 'post')
  $form.setAttribute('hidden', 'true')
  
  Object.entries(data).forEach(([key, value]) => {
    const $input = document.createElement('input')
    $input.setAttribute('name', key)
    $input.setAttribute('value', value)
    $form.appendChild($input)
  })
  
  const $csrfToken = document.createElement('input')
  $csrfToken.setAttribute('name', 'csrfmiddlewaretoken')
  $csrfToken.setAttribute('value', getCookie('csrftoken'))
  $form.setAttribute('action', url)
  
  $form.appendChild($csrfToken)
  document.body.appendChild($form)
  
  $form.submit()
}

export const preventMultiExecute = (e) => {
  e.target.disabled = true
  setTimeout(() => {
    e.target.disabled = false
  }, 2000)
}

export function imageError(image, replaceImg) {
  image.onerror = null
  image.src = replaceImg
}

export function copyToClipBoard(copyText) {
  const textarea = document.createElement('textarea')
  textarea.value = copyText
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export function setScreenSize() {
  
  function vh() {
    return window.innerHeight * 0.01
  }
  
  function setVh() {
    document.documentElement.style.setProperty('--vh', `${vh()}px`)
  }
  
  setVh()
  
  window.addEventListener('resize', function () {
    setVh()
    setTimeout(() => {
      setVh()
    }, 50)
  })
}