export const parseStatic = function (staticURL) {
  if (staticURL === undefined) {
    console.error('Run parseStatic Fail. Parameter Url is undefined, Please check')
    return undefined
  }
  return staticURL.replace(/&amp;/g, '&')
}

export const getStaticFolderURL = function (folderURL) {
  const folderStaticURL = parseStatic(folderURL)
  const lastSlashIndex = folderStaticURL.lastIndexOf('/')
  return folderStaticURL.slice(0, lastSlashIndex + 1)
}

export const getParam = function (sname, url = location.search) {
  let params = url.substr(url.indexOf('?') + 1)
  let sval = ''
  params = params.split('&')
  
  for (let i = 0; i < params.length; i++) {
    const temp = params[i].split('=')
    if (temp[0] === sname) {
      sval = temp[1]
    }
  }
  const param = decodeURI(sval)
  return param === 'undefined' ? undefined : param
}

export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

export const deleteQueryStringParameter = (uri, key) => {
  const re = new RegExp('[\\?&]' + key + '=[^&]+', 'i')
  return uri.replace(re, '').replace(/^&/, '?')
}