import { addCdn } from '@javascripts/utils/dom'

const CDN_URL = 'https://www.googleoptimize.com/optimize.js?id=OPT-NBMSTPR'

const defaultVariantMapper = {
  '1': 'a',
  '2': 'b',
  '3': 'c',
  '4': 'd',
  '5': 'e',
}

/**
 * AB 테스트 함수
 * @param optimizeId optimize 에서 발급해주는 실험 id
 * @param experimentId 실험 번호 (ex. p-ex13)
 * @param defaultVariant 호출 실패했을 때 기본으로 return 할 값
 * @param variantList variant 경우의 수
 * @param isRandom 호출 실패했을 때, 랜덤 variant 를 return 해 줄 지 여부 (이걸 세팅해줘야 모수 차이가 많이 나지 않는다.)
 * @param blockOptimize true 로 설정하면 optimize 호춣을 무시하고, defaultVariant 가 무조건 나오게 해준다.
 * @param callback abtest 값 return 후 callback 실행할 함수
 * @param variantMapper
 */
export function abTest(
  {
    optimizeId,
    experimentId,
    defaultVariant = 'a',
    variantList = ['a', 'b'],
    isRandom = true,
    blockOptimize = false,
    callback = undefined,
    variantMapper = defaultVariantMapper,
  },
) {
  const savedVariant = localStorage.getItem(experimentId.toLowerCase())
  
  if (savedVariant) {
    experiment(savedVariant, experimentId.toLowerCase(), callback)
    return
  }
  
  addCdn(CDN_URL, {
    async: true,
  })
  
  let isRunExperiment = false
  
  const experimentCallback = (value) => {
    if (isRunExperiment) return
    
    const variant = getVariant(value, defaultVariant, variantList, variantMapper, isRandom)
    experiment(variant, experimentId.toLowerCase(), callback)
    isRunExperiment = true
  }
  
  if (blockOptimize) {
    experimentCallback(defaultVariant)
  } else {
    gtag('event', 'optimize.callback', {
      name: optimizeId,
      callback: experimentCallback,
    })
  }
  
  setTimeout(() => {
    if (isRunExperiment) return
    
    const variant = getVariant(undefined, defaultVariant, variantList, variantMapper, isRandom)
    experiment(variant, experimentId.toLowerCase(), callback)
    isRunExperiment = true
  }, 1000)
}

function getVariant(value, defaultVariant, variantList, variantMapper, isRandom) {
  if (value in variantMapper) {
    return variantMapper[value]
  }
  
  if (isRandom) {
    const index = parseInt(Math.random() * variantList.length)
    return variantList[index]
  }
  
  return defaultVariant
}

function setVariantElem(variant, experimentId) {
  const variantElem = document.createElement('experiment')
  variantElem.setAttribute('id', experimentId)
  variantElem.setAttribute('variant', variant)
  variantElem.hidden = true
  
  document.body.appendChild(variantElem)
}

export function removeTestElem(variant, experimentId) {
  const abtestElements = document.querySelectorAll(`[data-abtest="${experimentId}"]`)
  
  abtestElements.forEach((abTestElement) => {
    if (abTestElement.dataset.variant.includes(variant)) {
      delete abTestElement.dataset.abtest
      delete abTestElement.dataset.variant
    } else {
      abTestElement.parentElement.removeChild(abTestElement)
    }
  })
}

function announceVariant(variant, experimentId) {
  localStorage.setItem(experimentId, variant)
  
  const completeEvent = new CustomEvent(experimentId, {
    detail: { variant },
  })
  
  const checkAmplitudeInterval = setInterval(() => {
    if (window.amplitude) {
      window.dispatchEvent(completeEvent)
      clearInterval(checkAmplitudeInterval)
    }
  }, 50)
}

function experiment(variant, experimentId, callback) {
  setVariantElem(variant, experimentId)
  removeTestElem(variant, experimentId)
  
  if (typeof callback === 'function') {
    callback(variant)
  }
  
  dataLayer.push({ 'event': 'ab-test' })
  announceVariant(variant, experimentId)
}

export function abTestSplash(experimentId, callback = undefined) {
  const variant = localStorage.getItem(experimentId.toLowerCase())
  
  if (variant !== null) removeTestElem(variant, experimentId)
  if (typeof callback === 'function') callback(variant)
  
  window.addEventListener(experimentId.toLowerCase(), (e) => {
    if (variant === null || variant === e.detail.variant) return
    location.reload()
  })
  
  return variant
}