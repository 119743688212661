import axios from 'axios'
import { stringify } from 'qs'
import querystring from 'querystring'
import { getFormData } from '@javascripts/utils/object'
import { getCookie } from '@javascripts/utils/cookie'
import { authTokenManager } from '@javascripts/utils/authTokenManager'

export const getServerBaseUrl = () => {
  switch (window.settings) {
    case 'settings_dev':
      return 'https://dev.lawandgood.pro'
    case 'settings_beta':
      return 'https://dev.lawandgood.pro'
    case 'settings_production':
      return 'https://www.lawandgood.com'
  }
}

const axiosConfig = {
  baseURL: getServerBaseUrl(),
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
}

const axiosInstance = axios.create(axiosConfig)

const setHeaderAuthToken = (config, accessToken) => {
  config.headers['Authorization'] = 'Bearer ' + accessToken
}

axiosInstance.interceptors.request.use(async (config) => {
  const newConfig = { ...config }
  
  const accessToken = authTokenManager.getAccessToken()
  if (authTokenManager.validateAuthToken(accessToken)) {
    setHeaderAuthToken(newConfig, accessToken)
    return newConfig
  }
  
  const refreshToken = authTokenManager.getRefreshToken()
  
  if (!authTokenManager.validateAuthToken(refreshToken)) {
    return newConfig
  }
  
  if (authTokenManager.isRefreshing) {
    await authTokenManager.callAfterRefreshing(({ accessToken: newAccessToken }) =>
      setHeaderAuthToken(newConfig, newAccessToken),
    )
    return newConfig
  }
  
  try {
    await authTokenManager.regenerateTokens()
    const newAccessToken = authTokenManager.getAccessToken()
    
    if (authTokenManager.validateAuthToken(newAccessToken)) {
      setHeaderAuthToken(newConfig, newAccessToken)
    }
  } catch (_e) {} finally {
    authTokenManager.allowRefreshing()
  }
  
  return newConfig
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (authTokenManager.checkRefreshRequiredByRemindMinute()) {
      authTokenManager.regenerateTokens().catch((e) => {
        // Refreshing failed but pass
      }).finally(() => {
        authTokenManager.allowRefreshing()
      })
    }
    
    return response
  },
  async (error) => {
    const { config, response } = error
    
    if (!config || response?.status !== 401 || config.url === '/auth/refresh' || config.isSent) {
      return Promise.reject(error)
    }
    
    if (authTokenManager.isRefreshing) {
      return authTokenManager.addRequest(config)
    }
    
    try {
      await authTokenManager.regenerateTokens()
      authTokenManager.callAllDelayedRequests(axiosInstance)
      
      return axiosInstance({ ...config, isSent: true })
    } catch (refreshError) {
      authTokenManager.rejectAllDelayedRequests()
      
      return Promise.reject(refreshError)
    } finally {
      authTokenManager.allowRefreshing()
      authTokenManager.clearRefreshQueue()
    }
  })

export function axiosGET(url, params, options = {}) {
  return axiosInstance.get(url, { params, ...options })
}

export function axiosQuerystringGET(url, params, options = {}) {
  return axiosInstance.get(`${url}?${querystring.stringify(params)}`, { ...options })
}

export function axiosPOST(url, params, options = {}) {
  const data = params instanceof FormData ? params : stringify(params)
  return axiosInstance.post(url, data, {
    ...options,
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      ...(options.headers || {}),
    },
  })
}

export function axiosJsonPOST(url, data, options = {}) {
  return axiosInstance.post(url, data, {
    ...options,
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      ...(options.headers || {}),
    },
  })
}

export function axiosFormPOST(url, params, options = {}) {
  if ('headers' in options) {
    options.headers['Content-Type'] = 'multipart/form-data'
  } else {
    options.headers = {
      'Content-Type': 'multipart/form-data',
    }
  }
  return axiosPOST(url, getFormData(params), {
    ...options,
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      ...(options.headers || {}),
    },
  })
}

export function axiosPUT(url, params, options = {}) {
  const data = params instanceof FormData ? params : stringify(params)
  return axiosInstance.put(url, data, {
    ...options,
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      ...(options.headers || {}),
    },
  })
}

export function axiosDELETE(url, params, options = {}) {
  return axiosInstance.delete(url, {
    params, ...options,
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      ...(options.headers || {}),
    },
  })
}

export function axiosPATCH(url, params, options = {}) {
  const csrftoken = getCookie('csrftoken')
  const data = params instanceof FormData ? params : stringify(params)
  
  // 기존 options.headers가 있을 수도 있으니 머지 처리
  const headers = {
    'X-CSRFToken': csrftoken,
    ...options.headers,
  }
  
  return axiosInstance.patch(url, data, {
    ...options,
    headers,
  })
}