import { getIsMobile } from '@javascripts/utils/dom'

export const clickQuestionLink = (e, option = {}) => {
  if (baseUser.isLawyer) {
    e.preventDefault()
    return Swal.fire({
      text: '신청서 작성은 일반회원만 이용할 수 있습니다.',
      confirmButtonText: '확인',
      ...option,
    })
  }
}

export const swalQuestionnaireAnonymous = (option = {}) => {
  const fontStyle = `font-size: ${getIsMobile() ? 15 : 17}px; color: #4B4133`
  
  Swal.fire({
    html: `<div style="${fontStyle}">법률 상담이 필요하신가요?<br>사건의뢰하고 변호사 제안 받으세요.</div>`,
    confirmButtonText: '사건의뢰하기',
    showCancelButton: true,
    cancelButtonText: '더 둘러보기',
    ...option,
  }).then((result) => {
    if (result.value) {
      location.href = '/quotation/question/' + option.choicePk
    }
  })
}

export const swalQuestionnaireOthers = (option = {}) => {
  const fontStyle = `font-size: ${getIsMobile() ? 15 : 17}px; color: #4B4133`
  
  Swal.fire({
    html: `<div style="${fontStyle}">모든 사건은 승인된 변호사에게만 공개됩니다.</div>
            <div style="${fontStyle}">이 분야에 사건을 겪고 있다면 사건을 의뢰해보세요.</div>`,
    confirmButtonText: '사건 의뢰 하러 가기',
    showCancelButton: true,
    cancelButtonText: '더 둘러보기',
    ...option,
  }).then((result) => {
    if (result.isConfirmed)
      location.href = ''
  })
}

export const swalQuestionnaireUnverified = (options = {}) => {
  const fontStyle = `font-size: ${getIsMobile() ? 15 : 17}px; color: #4B4133`
  
  if (baseUser.isWaiting) {
    
    Swal.fire({
      html: `<div style="${fontStyle}">변호사 인증 진행중입니다. 변호사 인증이 완료되어야 의뢰내용을 확인할 수 있습니다. (평균 1일 이내 인증완료)</div>`,
      confirmButtonText: '확인',
      customClass: 'GTM-view-verifying-notice-modal',
    })
    return
  }
  Swal.fire({
    html: `<div style="${fontStyle}">변호사 자격정보를 인증받아야 의뢰 내용을 확인할 수 있습니다. 변호사 인증은 간단하고 빠르게 진행됩니다.</div>`,
    confirmButtonText: '인증받기',
    showCancelButton: true,
    cancelButtonText: '더 둘러보기',
    customClass: 'GTM-view-need-verification-modal',
  }).then((r) => {
    if (r.isConfirmed) {
      location.href = baseUrls.editProfiles
    }
  })
}

export function swalDefaultError() {
  return Swal.fire({
    text: '잠시 후 다시 시도해주세요',
  })
}

export default {
  clickQuestionLink,
  swalQuestionnaireAnonymous,
  swalQuestionnaireOthers,
  swalQuestionnaireUnverified,
  swalDefaultError,
}